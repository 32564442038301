<template>
  <div :class="{'player-wrapper-container-fs' : inFullscreen}">
    <div
      v-if="videoRNData.status !== 'A'"
      class="player-wrapper"
      style="height: 25vh; width: 100vw;"
    >
      <v-row
        class="align-center justify-center row text-center"
        style="height: 25vh; width: 100vw;"
      >
        <v-col cols="6">
          <h5>
            {{ videoRNData.title_str }}
          </h5>
          <h6>{{ videoRNData.detail_str }}</h6>
        </v-col>
      </v-row>
    </div>
    <div
      v-else
      id="player-wrapper"
      class="player-wrapper"
    >
      <div
        v-if="videoRNData.video_type === 'S'"
        id="cams-container"
        class="cams-container"
      >
        <div
          class="text-center pa-0 "
        >
          <div
            style="flex: .4;"
            class="cam-btn text-center"
            @click="onSelectCam('left')"
          >
            <h5>1</h5>
          </div>
        </div>
        <div
          style="flex: 0.3; background-color: #ffff; color: #3f3f3f; opacity: 50%; max-width: 56px;"
          class="text-center pa-0 cam-btn mx-2"
        >
          <h5
            style="margin-top: 6px; font-weight: bolder"
          >
            Cam {{ camSelected }}
          </h5>
        </div>
        <div
          cols="2"
          class="text-center pa-0"
        >
          <div
            style="flex: .4;"
            class="cam-btn text-center"
            @click="onSelectCam('Right')"
          >
            <h5>2</h5>
          </div>
        </div>
      </div>

      <div
        v-if="highlightSelected === 0"
        id="action-container"
        :class="!startCreateHighlight ? 'actionRow' : 'actionRow-one'"
      >
        <div
          v-if="showCustomControls"
          class="actionsContainer"
        >
          <button
            v-if="!startCreateHighlight"
            class="actionBtn actionBtn-success"
            @click="onStartHighlight"
          >
            <h5>Start highlight</h5>
          </button>
          <div
            v-if="startCreateHighlight && !showInputName"
            style="display: flex;
            flex-direction: column;
            align-items: center"
            class="text-center"
          >
            <h5 class="timer text-white mb-2">
              {{ currentTimeVideo < 10 ? '00:0' + currentTimeVideo: '00:' + currentTimeVideo }}
            </h5>
            <button
              v-show="currentTimeVideo > 2"
              class="actionBtn"
              @click="onStopHighlight"
            >
              <h5>Stop highlight</h5>
            </button>
          </div>
          <!--
          <button
            v-if="manualViewer && !startCreateHighlight"
            class="actionBtn"
            @click="changeTo('m')"
          >
            <h6>Video Tracked</h6>
          </button> -->

          <!-- <button
            v-if="!manualViewer && !startCreateHighlight"
            class="actionBtn"
            @click="changeTo('t')"
          >
            <h6>Manual Viewer</h6>
          </button> -->
        </div>
      </div>

      <!-- eslint-disable-next-line vuejs-accessibility/media-has-caption -->
      <video
        id="player"
        :class="inFullscreen ? 'fs player video-js vjs-fluid vjs-layout-large' : 'player video-js vjs-fluid vjs-layout-large'"
        crossorigin="anonymous"
        preload="metadata"
        controls
        muted="muted"
        playsinline
        webkit-playsinline
        poster="/assets/images/fields/field-6.jpg"
      >

      </video>
    </div>

    <v-card-text
      v-if="!inFullscreen"
      class="flex-row px-3 overflow-y-auto pb-16 pl-7"
    >
      <h4 class="text-white">
        All Highlight ({{ videoRNData.highlights_count }})
      </h4>
      <v-list>
        <v-list-item
          v-for="(highlight, index) in videoRNData.highlights"
          :key="index"
          class="pl-0"
        >
          <v-list-item-avatar
            class="avatar-highlight"
            @click="onSelectHighlight(highlight)"
          >
            <v-img :src="highlight.thumbnail"></v-img>
            <v-icon
              v-if="highlightSelected !== highlight.id"
              size="25"
              style="position: absolute;"
              color="white"
            >
              {{ icons.mdiPlay }}
            </v-icon>
            <v-icon
              v-else
              size="25"
              style="position: absolute;"
              color="white"
            >
              {{ icons.mdiStop }}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title><h4>{{ highlight.name }}</h4></v-list-item-title>
            <v-list-item-subtitle>
              <h5 class="font-weight-light">
                {{ highlight.time_str + ' - ' + highlight.type_str }}
              </h5>
            </v-list-item-subtitle>
            <v-row
              v-if="highlight.users && highlight.users.length === 1"
              class="mt-1 pl-3 align-center"
            >
              <v-avatar
                size="20"
                class="mr-1"
              >
                <v-img :src="highlight.users[0].avatar"></v-img>
              </v-avatar>
              <h6 class="font-weight-light">
                {{ highlight.users[0].username }}
              </h6>
            </v-row>
            <v-row
              v-else
              class="mt-1 pl-3 align-center"
            >
              <v-avatar
                v-for="(i, item) in highlight.users"
                :key="i"
                size="20"
                class="mr-1"
              >
                <v-img :src="item.avatar"></v-img>
              </v-avatar>
            </v-row>
          </v-list-item-content>
          <v-list-item-action class="flex-row align-center">
            <div
              v-if="highlight.highlight_url"
              class="me-4"
              @click="doAction(highlight, 'shared')"
            >
              <svg
                height="20pt"
                width="20pt"
                viewBox="0 0 512 512"
              >
                <g
                  transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                  fill="#ffff"
                  stroke="none"
                >
                  <path d="M4499 4929 c-20 -4 -60 -18 -90 -30 -30 -11 -949 -300 -2044 -640 -1094 -340 -2007 -626 -2027 -635 -134 -60 -192 -230 -125 -366 16 -32 44 -70 63 -85 18 -16 454 -246 968 -513 l934 -485 488 -938 c338 -649 499 -949 524 -974 68 -70 169 -96 262 -69 70 20 130 64 161 118 14 24 298 921 632 1993 334 1073 625 2006 648 2075 49 150 54 230 18 322 -63 162 -251 266 -412 227z m-431 -569 c2 -22 -129 -157 -891 -918 -492 -490 -902 -892 -911 -892 -9 0 -352 174 -761 387 -648 337 -745 390 -745 410 0 12 6 25 13 30 30 19 3235 1011 3262 1010 24 -2 31 -7 33 -27z m322 -305 c10 -12 -78 -302 -465 -1547 -263 -843 -493 -1581 -512 -1640 -31 -99 -36 -108 -58 -108 -15 0 -30 9 -39 23 -32 51 -766 1470 -766 1480 0 19 1793 1807 1811 1807 9 0 22 -7 29 -15z" />
                </g>
              </svg>
              <!-- <v-icon
                color="grey lighten-1"
                @click="doAction(highlight, 'shared')"
              >
                {{ icons.mdiExportVariant }}
              </v-icon> -->
            </div>
            <div
              v-if="!highlight.highlight_url"
              class="flex-column text-center"
            >
              <v-progress-circular
                indeterminate
                color="light"
                size="20"
                width="2"
              >
              </v-progress-circular>
              <h6 class="font-weight-light">
                Creating
              </h6>
            </div>
            <div v-else>
              <v-icon
                v-if="highlight.can_delete"
                size="30"
                color="#FF3232"
                @click="removeHighlight(highlight)"
              >
                {{ icons.mdiTrashCanOutline }}
              </v-icon>
              <svg
                v-else
                height="20pt"
                width="20pt"
                viewBox="0 0 512 512"
                @click="addVideoId(highlight.id)"
              >
                <g
                  transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                  :fill=" includeVideoID(highlight.id) ? '#FFBA5C' : '#ffff'"
                  stroke="none"
                >
                  <path d="M1365 4672 c-120 -42 -205 -117 -258 -230 l-32 -67 -3 -2038 c-1 -1120 0 -2037 3 -2037 3 0 338 167 745 370 l740 370 740 -370 c407 -203 742 -370 745 -370 3 0 5 268 5 595 l0 595 -210 0 -210 0 0 -250 c0 -137 -2 -250 -5 -250 -3 0 -244 119 -535 265 l-530 265 -530 -265 c-291 -146 -532 -265 -535 -265 -3 0 -4 737 -3 1638 l3 1637 1065 0 1065 0 3 -107 3 -108 210 0 211 0 -4 163 c-3 158 -4 164 -35 229 -44 94 -109 160 -202 206 l-75 37 -1160 2 c-1081 2 -1164 1 -1211 -15z" />
                  <path d="M3840 3200 l0 -210 -212 -2 -213 -3 -3 -212 -2 -213 215 0 215 0 0 -215 0 -215 213 2 212 3 3 213 2 212 210 0 210 0 0 215 0 215 -210 0 -210 0 0 210 0 210 -215 0 -215 0 0 -210z" />
                </g>
              </svg>
              <!-- <v-icon
                v-else
                color="yellow darken-3"
                @click="doAction(highlight)"
              >
                {{ icons.mdiBookmarkOutline }}
              </v-icon> -->
            </div>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-dialog
      v-model="showInputName"
      :persistent="true"
      :width="'80vw'"
    >
      <v-card class="white">
        <v-card-title class="text-center justify-center">
          <h5 style="color: #000 !important;">
            Name your highlight
          </h5>
        </v-card-title>
        <v-card-text>
          <div
            class="inputContainer"
          >
            <v-text-field
              ref="nameInput"
              v-model="highlightName"
              dense
              outlined
              rounded
              :type="'text'"
              :placeholder="'Type the highlight name'"
              :error-messages="errorMessages.required"
              :rules="[validators.required]"
              hide-details
              class="white--text lg-theme"
            >
            </v-text-field>
            <v-row class="justify-center mt-1">
              <v-col
                cols="12"
                class="text-center"
              >
                <v-btn
                  class="mb-2 py-2 no-bg-hover grey-dark white--text w-full rounded-pill"
                  style="text-transform: inherit"
                  @click="onSaveHighlight"
                >
                  {{ 'Save' }}
                </v-btn>
                <v-btn
                  class="mt-1 py-2 no-bg-hover white black--text w-full rounded-pill"
                  style="text-transform: inherit; border: solid 1px black !important"
                  outlined
                  @click="onCancelHighlight"
                >
                  {{ 'Cancel' }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable no-lonely-if */
import {
  ref, computed, onMounted,
} from '@vue/composition-api'
import {
  mdiClose, mdiBookmarkOutline, mdiExportVariant, mdiCheckBold, mdiTrashCanOutline, mdiPlay, mdiStop,
} from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import {
  formatCurrency,
  isAndroid,
  isIOS,
  useRouter,
  defaultDownloadAppByOS,
} from '@core/utils'
import themeConfig from '@themeConfig'
import Axios from 'axios'
import { required, emailValidator, minLengthValidator } from '@core/utils/validation'
import RepeatRequest from '@core/utils/repeatRequest.services'
import moment from 'moment'

export default {
  components: {

  },
  setup() {
    const BASE_URL = 'https://api.fanaty.com/api/v1/'

    const repeat = new RepeatRequest()

    const { route } = useRouter()
    const { params, query } = route.value

    const { t } = useUtils()
    const startCreateHighlight = ref(false)
    const showInputName = ref(false)
    const highlightName = ref('')
    const currentTimeVideo = ref(1)
    const camSelected = ref(1)
    const highlightSelected = ref(0)
    const actionBtnsDom = ref(null)
    const camsContainerDom = ref(null)
    const newHighlight = ref({
      user_id: '',
      name: '',
      creation_date: moment().format('YYYY-MM-DD'),
      highlight_type: 'H',
      start_time: 0,
      end_time: 0,
      video_id: 0,
      recording_id: 0,
    })
    const manualViewer = ref(false)
    const videoRNData = ref({
      highlights: [],
      detail_str: '',
      status: 'A',
      status_str: '',
      highlights_times: [],
    })
    const listFolder = ref([])
    const errorMessages = ref([])
    const showCustomControls = ref(true)
    const myPlaylist = ref([])
    const inFullscreen = ref(false)
    const isAndroidComputed = computed(() => isAndroid())
    const isIOSComputed = computed(() => isIOS())
    let player = null
    let timeoutHighlight = null
    let intervalHighlight = null

    function restartPlayer(options) {
      player.dispose()

      const elplayer = document.createElement('video')
      elplayer.setAttribute('id', 'player')
      elplayer.setAttribute('controls', 'player')
      elplayer.setAttribute('crossorigin', 'anonymous')
      elplayer.setAttribute('preload', 'metadata')
      elplayer.setAttribute('muted', 'muted')
      elplayer.setAttribute('autoplay', 'true')
      elplayer.classList.add('video-js')
      elplayer.classList.add('player')
      elplayer.classList.add('vjs-fluid')

      const wrapper = document.getElementById('player-wrapper')
      wrapper.appendChild(elplayer)
      player = window.videojs('player', {
        ...options,
        playbackRateMenuButton: false,
        disablePictureInPicture: true,
        aspectRatio: '16:9',
        controlBar: {
          fullscreenToggle: false,
        },
      })
    }

    function setManualViewerVideo(currentTime) {
      try {
        const highlightTime = videoRNData.value.highlights_times.map((timeh, ih) => ({
          time: timeh, text: String(timeh), overlayText: String(ih),
        }))
        player.src({ type: 'video/mp4', src: videoRNData.value.stitched_video_url })
        // eslint-disable-next-line no-use-before-define
        if (videoRNData.value.stitched_video_url) {
        // eslint-disable-next-line no-use-before-define
          generateBarManualViewerBtn(player, 'vjs-icon-cam')
        }
        player.nuevo({
          shareMenu: false,
          zoomMenu: false,
          contextMenu: false,
          relatedMenu: false,
          rateMenu: false,
          settingsButton: false,
        })
        player.vr({ motionControls: true, projection: '360', nav: false })
        player.load()
        player.ready(() => {
          player.play()
          player.currentTime(currentTime)
        })

        player.one('loadedmetadata', () => {
          if (videoRNData.value.stitched_video_url && (videoRNData.value.left_video_url || videoRNData.value.video_url)) {
          // eslint-disable-next-line no-use-before-define
            generateBarManualViewerBtn(player, 'vjs-icon-cam')
          }
          // eslint-disable-next-line no-use-before-define
          generateRateBtn(player)
          // eslint-disable-next-line no-use-before-define
          generateFullScreenBtn(player, inFullscreen ? 'vjs-icon-fs' : 'vjs-icon-fsExit', 12)
          // eslint-disable-next-line no-use-before-define
          setHighlightMarks(player, highlightTime)
          document.getElementById('player-wrapper').click()
          player.play()
          player.muted(false)
          setTimeout(() => {
          // eslint-disable-next-line no-use-before-define
            addBtnActionsInsidePlayer(actionBtnsDom.value, camsContainerDom.value)
          }, 5000)
          console.log('🚀 ~ player.one ~ inFullscreen.value:', inFullscreen.value)

          if (inFullscreen.value) {
            console.log('🚀 ~ player.one ~ inFullscreen.value:', inFullscreen.value)
            // eslint-disable-next-line no-use-before-define
            generateFullScreenBtn(player)
            document.getElementsByClassName('vjs-control-bar')[0].classList.remove('fs-bar')
          } else {
            // eslint-disable-next-line no-use-before-define
            generateFullScreenBtn(player, 'vjs-icon-fsExit')
            document.getElementsByClassName('vjs-control-bar')[0].classList.add('fs-bar')
          }
        })
      } catch (error) {
        console.log('🚀 ~ setManualViewerVideo ~ error:', error)
      }
    }

    function setTrackedVideo(currentTime) {
      const highlightTime = videoRNData.value.highlights_times.map((timeh, ih) => ({
        time: timeh, text: String(timeh), overlayText: String(ih),
      }))
      player.nuevo({
        playlistUI: false,
        playlistShow: false,
        playlistAutoHide: false,
        playlistNavigation: false,
        playlistRepeat: false,
        shareMenu: false,
        settingsButton: false,
      })
      if (videoRNData.value.video_type === 'S') {
        player.thumbnails({ responsive: true })
        myPlaylist.value = [
          {
            sources: [{ src: videoRNData.value.left_video_url || videoRNData.value.video_url, type: 'video/mp4' }],
            thumb: '',
            slideImage: '',
            title: 'video',
            duration: '01:13',
          },
          {
            sources: [{ src: videoRNData.value.right_video_url, type: 'video/mp4' }],
            thumb: '',
            slideImage: '',
            title: 'video',
            duration: '01:13',
          },
        ]

        // player.playlist(myplaylist)
      }
      if (videoRNData.value.stitched_video_url) {
        // eslint-disable-next-line no-use-before-define
        generateBarManualViewerBtn(player, 'vjs-icon-vr')
      }
      player.src({ type: 'video/mp4', src: videoRNData.value.left_video_url || videoRNData.value.video_url })

      player.load()
      player.ready(() => {
        player.currentTime(currentTime)
      })
      player.one('loadedmetadata', () => {
        if (videoRNData.value.stitched_video_url && (videoRNData.value.left_video_url || videoRNData.value.video_url)) {
          // eslint-disable-next-line no-use-before-define
          generateBarManualViewerBtn(player)
        }
        // eslint-disable-next-line no-use-before-define
        generateRateBtn(player)
        // eslint-disable-next-line no-use-before-define
        generateFullScreenBtn(player, inFullscreen ? 'vjs-icon-fs' : 'vjs-icon-fsExit')

        // eslint-disable-next-line no-use-before-define
        setHighlightMarks(player, highlightTime)
        player.play()
        player.muted(false)
        setTimeout(() => {
          // eslint-disable-next-line no-use-before-define
          addBtnActionsInsidePlayer(actionBtnsDom.value, camsContainerDom.value)
        }, 1000)
        if (inFullscreen.value) {
          console.log('🚀 ~ player.one ~ inFullscreen.value:', inFullscreen.value)
          // eslint-disable-next-line no-use-before-define
          generateFullScreenBtn(player)
          document.getElementsByClassName('vjs-control-bar')[0].classList.remove('fs-bar')
        } else {
        // eslint-disable-next-line no-use-before-define
          generateFullScreenBtn(player, 'vjs-icon-fsExit')
          document.getElementsByClassName('vjs-control-bar')[0].classList.add('fs-bar')
        }
      })
    }

    function changeTo(options = {}) {
      const currentTime = player.currentTime()
      restartPlayer(options)
      if (!manualViewer.value) {
        manualViewer.value = true
        setManualViewerVideo(currentTime, videoRNData)
      } else {
        manualViewer.value = false
        setTrackedVideo(currentTime, videoRNData)
      }
    }

    function generateBarManualViewerBtn(elplayer, className = 'vjs-icon-vr') {
      player.getChild('controlBar').removeChild('vrbtn', {}, 8)
      const btn = window.videojs.getComponent('Button')
      const vrBtn = window.videojs.extend(btn, {
        constructor() {
          // eslint-disable-next-line prefer-rest-params
          btn.apply(this, arguments)
          this.addClass(className)
        },
        handleClick() {
          changeTo()
        },
      })

      window.videojs.registerComponent('vrbtn', vrBtn)
      elplayer.getChild('controlBar').addChild('vrbtn', {}, 8)
    }

    function ingreseRate() {
      const currentRate = player.playbackRate()
      player.getChild('controlBar').removeChild('rateBtn', {}, 9)
      if (currentRate === 1) {
        player.playbackRate(2)
        // eslint-disable-next-line no-use-before-define
        generateRateBtn(player, 'vjs-icon-2x')
      } else {
        player.playbackRate(1)
        // eslint-disable-next-line no-use-before-define
        generateRateBtn(player, 'vjs-icon-1x')
      }
    }

    function generateRateBtn(elplayer, className = 'vjs-icon-1x') {
      const btn = window.videojs.getComponent('Button')
      const rateBtn = window.videojs.extend(btn, {
        constructor() {
          // eslint-disable-next-line prefer-rest-params
          btn.apply(this, arguments)
          this.addClass(className)
        },
        handleClick() {
          ingreseRate()
        },
      })

      window.videojs.registerComponent('rateBtn', rateBtn)
      elplayer.getChild('controlBar').addChild('rateBtn', {}, 9)
    }

    function setFullScreen(elPlayer) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage('fullscreenchange')
      }

      console.log('🚀 ~ setFullScreen ~ inFullscreen.value:', inFullscreen.value)
      if (inFullscreen.value) {
        inFullscreen.value = false

        // eslint-disable-next-line no-use-before-define
        generateFullScreenBtn(elPlayer)
        document.getElementsByClassName('vjs-control-bar')[0].classList.remove('fs-bar')
      } else {
        inFullscreen.value = true
        // eslint-disable-next-line no-use-before-define
        generateFullScreenBtn(elPlayer, 'vjs-icon-fsExit')
        document.getElementsByClassName('vjs-control-bar')[0].classList.add('fs-bar')
      }
    }

    function generateFullScreenBtn(elplayer, className = 'vjs-icon-fs', position = 11) {
      player.getChild('controlBar').removeChild('fsBtn', {}, position)
      const btn = window.videojs.getComponent('Button')
      const fsBtn = window.videojs.extend(btn, {
        constructor() {
          // eslint-disable-next-line prefer-rest-params
          btn.apply(this, arguments)
          this.addClass(className)
        },
        handleClick() {
          setFullScreen(elplayer)
        },
      })

      window.videojs.registerComponent('fsBtn', fsBtn)
      elplayer.getChild('controlBar').addChild('fsBtn', {}, position)
    }

    // function generateRateBtn(elplayer, className = 'vjs-icon-rate') {
    //   const rate = elplayer.playbackRate()
    //   const btn = window.videojs.getComponent('Button')
    //   const vrBtn = window.videojs.extend(btn, {
    //     constructor() {
    //       // eslint-disable-next-line prefer-rest-params
    //       btn.apply(this, arguments)
    //       this.addClass(className)
    //       this.controlText(`${rate}X`)
    //     },
    //     handleClick() {
    //       elplayer.playbackRate(rate === 1 ? 2 : 1)
    //     },
    //   })

    //   window.videojs.registerComponent('vrbtn', vrBtn)
    //   elplayer.getChild('controlBar').addChild('vrbtn', {}, 9)
    // }

    function playHighlightLoop(startTime, endTime, currentTime, elplayer, loop = false) {
      if (currentTime >= endTime && loop) {
        elplayer.currentTime(startTime)
      }
    }

    function onStopHighlight() {
      clearTimeout(timeoutHighlight)
      clearInterval(intervalHighlight)
      showInputName.value = true
      newHighlight.value = {
        ...newHighlight.value,
        end_time: player.currentTime(),
      }
      player.currentTime(newHighlight.value.start_time)
      player.on('timeupdate', () => {
        const current = player.currentTime()
        playHighlightLoop(newHighlight.value.start_time, newHighlight.value.end_time, current, player, true)
      })
    }

    function onStartHighlight() {
      const p = document.getElementsByClassName('vjs-touch-nav')
      p[0].style = 'display: none'
      player.play()
      startCreateHighlight.value = true
      newHighlight.value = {
        name: '',
        start_time: player.currentTime(),
      }
      player.controls(false)
      timeoutHighlight = setInterval(() => {
        onStopHighlight()
      }, 30000)
      let elcurrentTime = 1
      intervalHighlight = setInterval(() => {
        elcurrentTime += 1
        currentTimeVideo.value = Math.floor(elcurrentTime)
      }, 1000)
    }

    function onSelectHighlight(item) {
      if (highlightSelected.value === item.id) {
        highlightSelected.value = 0
      } else {
        player.currentTime(item.start_second)
      }
    }

    async function getRecordingDetailBy() {
      const axions = new Axios.Axios({})
      try {
        // const token = 'yJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0ZXN0MDEiLCJpYXQiOjE3MjY1NzUwNzMsIm5iZiI6MTcyNjU3NTA3MywianRpIjoiYWYxNmZlZjAtZGIxMC00Njc5LWJjMGUtMzhkNjVjNzQxMzY0IiwiZXhwIjoxNzI2NTc1OTczLCJ0eXBlIjoiYWNjZXNzIiwiZnJlc2giOmZhbHNlLCJzY29wZXMiOlsibW9iaWxlX3VzZXIiXX0.wcrUvilQlz-5WFgVvo9YgLG3v6JdrOOxCgpCERQkZrg'
        const { data } = await axions.get(`${BASE_URL}recording/mobile/${params.user}`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language': 'es',
            'el-token': `Bearer ${params.hash}`,
          },
        })
        const videoInfo = JSON.parse(data)

        if (videoInfo.ok) {
          videoRNData.value = { ...videoInfo.data }
          // eslint-disable-next-line no-use-before-define
          checkHighlightStatus(videoInfo.highlights)
        }
      } catch (error) {
        console.log('🚀 ~ getRecordingDetailBy ~ error:', error)
      }
    }

    async function getVideoDetailby() {
      console.log('params', params)
      const axions = new Axios.Axios({})
      try {
        const { data } = await axions.get(`${BASE_URL}highlight/video/mobile/${params.user}`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language': 'es',
            'el-token': `Bearer ${params.hash}`,
          },
        })
        const videoInfo = JSON.parse(data)

        if (videoInfo.ok) {
          videoRNData.value = { ...videoInfo.data }
          // eslint-disable-next-line no-use-before-define
          checkHighlightStatus(videoInfo.data.highlights)
        }
      } catch (error) {
        console.log('🚀 ~ getRecordingDetailBy ~ error:', error)
      }
    }
    const reloadHighlight = async () => {
      if (query.entity === 'recording') {
        await getRecordingDetailBy(params.user)
      } else {
        await getVideoDetailby(params.user)
      }
    }

    function checkHighlightStatus(highlights = []) {
      console.log('🚀 ~ checkHighlightStatus ~ highlights:', highlights)
      const highlightNotUrl = highlights.find(hightlight => hightlight?.highlight_url == null)
      console.log('🚀 ~ checkHighlightStatus ~ highlightNotUrl:', highlightNotUrl)
      if (highlightNotUrl) {
        repeat.stopInterval()
        repeat.repeat(5000, reloadHighlight)
      } else {
        repeat.stopInterval()
      }
    }

    async function requestCreateHighlight(body) {
      const axions = new Axios.Axios({})
      const strdata = JSON.stringify(body)
      try {
        const { data } = await axions.post(`${BASE_URL}highlight/`, strdata, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language': 'es',
            'el-token': `Bearer ${params.hash}`,
          },
        })
        const videoInfo = JSON.parse(data)
        console.log('🚀 ~ requestCreateHighlight ~ videoInfo:', videoInfo)

        if (videoInfo.ok) {
          if (query.entity === 'recording') {
            getRecordingDetailBy(params.user)
          } else {
            getVideoDetailby(params.user)
          }
        }
      } catch (error) {
        console.log('🚀 ~ getRecordingDetailBy ~ error:', error)
      }
    }

    function onCreateHighlight() {
      player.controls(true)
      startCreateHighlight.value = false
      showInputName.value = false
      console.log(newHighlight.value, highlightName)
      const currentTime = player.currentTime()
      restartPlayer({})
      setTrackedVideo(currentTime)
      const body = {
        ...newHighlight.value,
        name: highlightName.value,
        user_id: 'test01',
        creation_date: moment().format('YYYY-MM-DD'),
        video_id: query?.entity === 'recording' ? null : params.user,
        recording_id: query?.entity === 'recording' ? params.user : null,
        highlight_type: 'H',
      }
      requestCreateHighlight(body)
    }

    function doAction(item, action = 'highlight') {
      const data = JSON.stringify({ action, item })
      window.ReactNativeWebView.postMessage(data)
    }

    function getFolders() {
      const axions = new Axios.Axios({})
      axions.get(`${BASE_URL}user/videos_folder/`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Accept-Language': 'es',
          'el-token': `Bearer ${params.hash}`,
        },
      })
        .then(response => {
          console.log('🚀 ~ getFolders ~ response.data.data:', response)
          const resp = JSON.parse(response.data)
          if (Array.isArray(resp.data)) listFolder.value = [...resp.data]
        })
        .catch(error => {
          console.log(error)
        })
    }

    async function removeHighlight(item) {
      try {
        const axions = new Axios.Axios({})
        const response = await axions.delete(`${BASE_URL}highlight/${item.id}`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language': 'es',
            'el-token': `Bearer ${params.hash}`,
          },
        })
        const resp = JSON.parse(response.data)
        console.log('🚀 ~ removeHighlight ~ resp:', resp)
        if (resp.ok === 1) {
          if (query.entity === 'recording') {
            getRecordingDetailBy(params.user)
          } else {
            getVideoDetailby(params.user)
          }

          setTimeout(() => {
            if (query.entity === 'recording') {
              getRecordingDetailBy(params.user)
            } else {
              getVideoDetailby(params.user)
            }
          }, 60000)
        }
      } catch (error) {
        if (error?.data && error.data.message) {
          // showToast(error.data.message, 'error')
        }
      }
    }

    function updateFolders() {
      console.log(listFolder)
      const axions = new Axios.Axios({})
      const strdata = JSON.stringify({ videos_folders: listFolder.value })
      axions.put(`${BASE_URL}user/videos_folder/`, strdata, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Accept-Language': 'es',
          'el-token': `Bearer ${params.hash}`,
        },
      })
        .then(response => {
          console.log('update', response)
          getFolders()
        })
        .catch(error => {
          console.log(error)
        })
    }

    function includeVideoID(videoId) {
      if (listFolder.value.length) return listFolder.value[0].videos_ids.includes(videoId)

      return false
    }

    function addVideoId(videoId) {
      console.log(listFolder.value)
      const newlist = listFolder.value[0]
      if (includeVideoID(videoId)) {
        newlist.videos_ids = newlist.videos_ids.filter(i => i !== videoId)
      } else {
        newlist.videos_ids.push(videoId)
      }

      listFolder.value = [newlist]
      setTimeout(() => {
        updateFolders()
      }, 1000)
    }
    function onCancelHighlight() {
      player.controls(true)
      startCreateHighlight.value = false
      showInputName.value = false
      console.log(newHighlight.value, highlightName)
      const currentTime = player.currentTime()
      restartPlayer({})
      setTrackedVideo(currentTime)
    }

    function setHighlightMarks(elplayer, highlightTime = []) {
      const total = elplayer.duration()
      const p = document.getElementsByClassName('vjs-progress-holder vjs-slider vjs-slider-horizontal')

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < highlightTime.length; i++) {
        // eslint-disable-next-line no-mixed-operators
        const left = `${highlightTime[i].time / total * 100}%`

        const { time } = highlightTime[i]
        const el = document.createElement('div')
        el.setAttribute('style', `left: ${left}`)
        el.setAttribute('data-time', `${time}`)
        el.classList.add('js-marker')
        p[0].append(el)
      }
    }

    function onSelectCam(mode) {
      const currentTime = player.currentTime()
      if (mode === 'left') {
        camSelected.value = 1
        restartPlayer({})
        player.src(videoRNData.value.left_video_url)
        player.currentTime(currentTime)
      } else {
        player.src(videoRNData.value.right_video_url)
        player.currentTime(currentTime)
        camSelected.value = 2
      }
      const highlightTime = videoRNData.value.highlights_times.map((timeh, ih) => ({
        time: timeh, text: String(timeh), overlayText: String(ih),
      }))
      player.nuevo({
        playlistUI: false,
        playlistShow: false,
        playlistAutoHide: false,
        playlistNavigation: true,
        playlistRepeat: false,
        shareMenu: false,
        settingsButton: false,
      })
      player.one('loadedmetadata', () => {
        setHighlightMarks(player, highlightTime)
        if (videoRNData.value.stitched_video_url && (videoRNData.value.left_video_url || videoRNData.value.video_url)) {
          generateBarManualViewerBtn(player)
        }
        generateRateBtn(player)
        generateFullScreenBtn(player, inFullscreen ? 'vjs-icon-fs' : 'vjs-icon-fsExit', 12)
        player.play()
        player.muted(false)
        // eslint-disable-next-line no-use-before-define
        addBtnActionsInsidePlayer(actionBtnsDom.value, camsContainerDom.value)
        if (inFullscreen.value) {
        // eslint-disable-next-line no-use-before-define
          generateFullScreenBtn(player)
          document.getElementsByClassName('vjs-control-bar')[0].classList.remove('fs-bar')
        } else {
        // eslint-disable-next-line no-use-before-define
          generateFullScreenBtn(player, 'vjs-icon-fsExit')
          document.getElementsByClassName('vjs-control-bar')[0].classList.add('fs-bar')
        }
      })
    }

    function addBtnActionsInsidePlayer(actions, cams) {
      document.querySelectorAll('.player')[0].appendChild(actions)
      document.querySelectorAll('.player')[0].appendChild(cams)
      console.log('🚀 ~ addBtnActionsInsidePlayer ~ p:', actions)
      document.querySelectorAll('.vjs-time-divider')[0].classList.remove('vjs-time-control')
    }

    onMounted(async () => {
      await reloadHighlight()
      getFolders()

      const videoInfo = videoRNData.value
      videoRNData.value = { ...videoInfo }
      actionBtnsDom.value = document.getElementById('action-container')
      camsContainerDom.value = document.getElementById('cams-container')
      const highlightTime = videoInfo.highlights_times.map((timeh, ih) => ({
        time: timeh, text: String(timeh), overlayText: String(ih),
      }))
      player = window.videojs('player', {
        disablePictureInPicture: true,
        aspectRatio: '16:9',
        controlBar: {
          fullscreenToggle: false,
        },

      })
      player.nuevo({
        playlistUI: false,
        playlistShow: false,
        playlistAutoHide: false,
        playlistNavigation: false,
        playlistRepeat: false,
        shareMenu: false,
        settingsButton: false,
      })
      if (videoInfo.video_type === 'S') {
        myPlaylist.value = [
          {
            sources: [{ src: videoInfo.left_video_url || videoInfo.video_url, type: 'video/mp4' }],
            thumb: '',
            slideImage: '',
            title: 'Left Camera',
            duration: '',
          },
          {
            sources: [{ src: videoInfo.right_video_url, type: 'video/mp4' }],
            thumb: '',
            slideImage: '',
            title: 'Right Camera',
            duration: '',
          },
        ]

        // player.playlist(myplaylist)
      }
      if (!videoInfo.video_url && !videoInfo.left_video_url) {
        manualViewer.value = true
        showCustomControls.value = false
        player.vr({ motionControls: true, projection: '360', nav: false })
        player.src({ type: 'video/mp4', src: videoInfo.stitched_video_url })
      } else {
        player.src({ type: 'video/mp4', src: videoInfo.left_video_url || videoInfo.video_url })
      }
      player.ready(() => {
        // const pLabel = document.getElementsByClassName('p-label')
        // pLabel[0].innerHTML = 'Cameras'
      })

      player.one('loadedmetadata', () => {
        if (videoInfo.stitched_video_url && (videoInfo.left_video_url || videoInfo.video_url)) {
          generateBarManualViewerBtn(player)
        }
        generateRateBtn(player)
        generateFullScreenBtn(player)

        // generateRateBtn(player)
        const total = player.duration()
        console.log(total)

        // eslint-disable-next-line no-underscore-dangle
        const p = document.getElementsByClassName('vjs-progress-holder vjs-slider vjs-slider-horizontal')

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < highlightTime.length; i++) {
          // eslint-disable-next-line no-mixed-operators
          const left = `${highlightTime[i].time / total * 100}%`

          const { time } = highlightTime[i]
          const el = document.createElement('div')
          el.setAttribute('style', `left: ${left}`)
          el.setAttribute('data-time', `${time}`)
          el.classList.add('js-marker')
          p[0].append(el)
        }

        player.play()
        player.muted(false)
      })
      setTimeout(() => {
        addBtnActionsInsidePlayer(actionBtnsDom.value, camsContainerDom.value)
      }, 3000)
    })

    return {
      // data
      newHighlight,
      manualViewer,
      startCreateHighlight,
      showInputName,
      errorMessages,
      validators: {
        required,
        emailValidator,
        minLengthValidator,
      },
      highlightName,
      currentTimeVideo,
      highlightSelected,
      showCustomControls,
      camSelected,
      inFullscreen,

      // methods
      formatCurrency,
      defaultDownloadAppByOS,
      changeTo,
      isAndroidComputed,
      isIOSComputed,
      videoRNData,
      onStartHighlight,
      onStopHighlight,
      onSaveHighlight: onCreateHighlight,
      doAction,
      onSelectHighlight,
      addVideoId,
      includeVideoID,
      removeHighlight,
      onCancelHighlight,
      onSelectCam,

      // themeConfig
      appLogoTitle: themeConfig.app.logoTitle,
      t,
      icons: {
        mdiClose,
        mdiBookmarkOutline,
        mdiExportVariant,
        mdiCheckBold,
        mdiTrashCanOutline,
        mdiPlay,
        mdiStop,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/view.scss';
@import '@core/preset/preset/pages/landing.scss';
</style>
<style lang="scss" scoped>

.absolute-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #222222;
  color: #ffffff6b;
  border-radius: 50px !important;
  padding: 2px;
}

.border-img {
  border-radius: 10px;
}

.text-mail {
  color: #FF3232;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.01875rem;
  text-decoration: none;
  white-space: nowrap;
}

.bg-grey {
  background-color: #979797;
}

.player-wrapper {
  width: 100vw;
  position: relative;
}
</style>
<style class="vjs-styles-dimensions">
.player-dimensions {
  width: 100%;
  height: 100%;
}

.player-dimensions.vjs-fluid {
    padding-top: 56.25%;
    min-height: calc(21vw + 22vh - 1rem);
}
.vjs-big-play-button {
  display: none;
}

.actionRow {
  position: absolute;
  top: 64%;
  z-index: 2;
  width: 100%;
}

.actionRow-one {
  position: absolute;
  top: 54%;
  z-index: 2;
  width: 100%;
}

.actionsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.actionBtn {
    width: 34vw !important;
    height: 3.5vh !important;
    min-height: 35px !important;
    border-radius: 3vh !important;
    background-color: #FF3232 !important;
    color: #fff !important;
    opacity: 0.8 !important;
    max-width: 160px !important;
    font-family:"Open Sans", sans-serif;
    padding-inline: 5px;
}
.actionBtn h5 {
  font-weight: bold !important;
  font-size: 14px !important;
}

.actionBtn-success {
  background-color: #14AE5C !important;
}

.video-js .vjs-time-control {
  display: block !important;
  padding-left: 1px;
  padding-right: 1px;
}

.vjs-button-vr {
  display: none !important;
}

.video-js .vjs-progress-control .vjs-progress-holder {
  margin-inline: 0px;
}

.inputContainer{
  margin: auto;
  width: 80%;
  /* padding: .5rem; */
  border-radius: 1em;
}
.actionRow-one:has(.inputContainer){
  top: 5% !important;
  left: 10%;
  right: 10%;
}
.timer {
  background: rgba(0, 0,0, 0.8);
  text-align: center;
  display: inline-block;
  width: 25vw;
  border-radius: 1rem;
  padding: 5px;
  margin-top: 10px;
  color: white;
  font-weight: bolder;
}
.video-js .vjs-slider {
  background-color: transparent !important
}
.vjs-playlist-nav {
  background: transparent !important;

}
.vjs-grid .vjs-arrow .vjs-prev, .vjs-playlist-nav .vjs-prev, .vjs-grid .vjs-arrow .vjs-next, .vjs-playlist-nav .vjs-next {
    font-size: 25px;
}

.js-marker {
    position: absolute;
    background: #fff;
    width: 3px;
    height: 6px;
    top: 40%;
    z-index: 30;
    margin-left: -3px;
}

.vjs-480 .vjs-progress-holder .js-marker{
  top: 0px !important;
}
.js-marker:hover
{
  opacity:1;
}

.input-control > .v-input__slot{
  background: #58585b17;
}

.v-text-field__slot > input::placeholder {
  color: #9e9e9e !important
}
.vjs-control-bar > button.vjs-control.vjs-button.vjs-icon-vr{
  background-image: url('../../assets/images/svg/rotate-3d.svg') !important;
  background-size: contain !important;
  background-position-y: 0.5rem;
  background-position-x: 0.2rem;
  margin-right: 5px;
}

.vjs-control-bar > button.vjs-control.vjs-button.vjs-icon-cam{
  background-image: url('../../assets/images/svg/video-outline.svg') !important;
  background-size: contain !important;
  background-position-y: 0.4rem;
  background-position-x: 0.2rem;
  margin-right: 5px;
}

.vjs-control-bar > button.vjs-control.vjs-button.vjs-icon-1x{
  background-image: url('../../assets/images/svg/1x.svg') !important;
  background-size: inherit !important;
  background-position: center !important;
  margin-right: 5px;
}

.vjs-control-bar > button.vjs-control.vjs-button.vjs-icon-2x{
  background-image: url('../../assets/images/svg/2x.svg') !important;
  background-size: inherit !important;
  background-position: center !important;
  margin-right: 5px;
}

.vjs-control-bar > button.vjs-control.vjs-button.vjs-icon-fs{
  background-image: url('../../assets/images/svg/fullscreen.svg') !important;
  background-size: inherit !important;
  background-position: center !important;
  margin-right: 5px;
}

.vjs-control-bar > button.vjs-control.vjs-button.vjs-icon-fsExit{
  background-image: url('../../assets/images/svg/fullscreen-exit.svg') !important;
  background-size: inherit !important;
  background-position: center !important;
  margin-right: 5px;
}

.video-js .vjs-volume-panel.vjs-volume-panel-vertical {
    width: auto;
}
.video-js.vjs-fluid:not(.vjs-audio-only-mode), .video-js.vjs-16-9:not(.vjs-audio-only-mode), .video-js.vjs-4-3:not(.vjs-audio-only-mode), .video-js.vjs-9-16:not(.vjs-audio-only-mode), .video-js.vjs-1-1:not(.vjs-audio-only-mode) {
    height: auto;
}

.video-js .vjs-tech {
    outline: 0;
    width: 100%;
    height: 96% !important;
    display: inline-block;
    position: absolute;
    top: -20px !important;
    left: 0;
}

.video-js .vjs-touch-nav {
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    color: #fff;
    transition: visibility .4s,opacity .4s;
    display: flex;
    position: absolute;
    top: 40% !important;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100% !important;
    justify-content: space-between;
}

.cams-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  top: 5%;
  z-index: 2;
  width: 100%;
  padding-inline: 15%;

}

.cam-btn{
  border-radius: 30px;
  width: 80%;
  background-color: #000;
  padding: 6px 6px;
  min-width: 40px;
  opacity: 0.5;
  color: #fff;
  font-family:"Open Sans", sans-serif !important
}

.video-js .vjs-big-play-button {
display: none !important;
}
.video-js .vjs-progress-control .vjs-play-progress {
    background-color: #FF3232;
    width: 0;
}
.video-js .vjs-load-progress div {
    background-color: #AAAAAA;
    opacity: 1;
}
.vjs-progress-control .vjs-slider:before {
    background: #999999 !important;
}
.video-js .vjs-replay-button {
    color: #fff;
    cursor: pointer;
    background: 0 0;
    border: 0;
    width: auto;
    display: none;
    position: absolute;
    top: 40% !important;
    left: 50%;
    transform: translate(-50%,-50%);
}
.vjs-touch-inactive:not(.vjs-ad-playing):not(.vjs-audio):not(.vjs-has-mouse) .vjs-control-bar {
    opacity: 1 !important;
    visibility: visible !important;
}
.vjs-control-bar {

    bottom: 0px;
    /* top: 20px; */
}

.video-js {
    background-color: #000 !important;
}
.video-js .vjs-picture-in-picture-control, .video-js .vjs-pip-control {
    display: none !important;
}
.video-js .vjs-time-control {
    min-width: 0em !important;
}

.avatar-highlight{
  width: 12vw !important;
  height: 12vw !important;
}
.vjs-time-divider{
  color: #000 !important;
}

.video-js .vjs-progress-control .vjs-load-progress, .vjs-progress-control .vjs-play-progress {
    height: 5px !important;
}

.vjs-480 .vjs-control-bar .vjs-progress-control {
    width: 100%;
    margin-left: 0;
    position: absolute;
    top: -5px;
    left: 0;
    height: 40px;
}

.video-js .vjs-control-bar {
    bottom: 1px !important;
    left: 0;
    right: 0;
}
.player-wrapper-container-fs {
    height: 100vh;
    overflow: hidden;
}

.fs .video-js .vjs-tech {
    outline: 0;
    width: 90%;
    height: 100%;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 5%;
}
#player > div.vjs-control-bar.fs-bar {
    bottom: 20%!important;
}
</style>
